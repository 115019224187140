

























































import Vue from 'vue'
import IntermediateHeader from '@/components/Brand/IntermediateHeader.vue'
import Component from 'vue-class-component'
import DataAccessSubscriptionPaymentPopup from '@/components/Brand/DataAccessSubscriptionPaymentPopup.vue'

@Component({
  components: {
    IntermediateHeader,
    DataAccessSubscriptionPaymentPopup
  }
})
export default class DataAccessSubscriptionCheckPage extends Vue {
  public busy: boolean = true
  public loadingBrand = true
  public brandSubscriptionDetails: any = {}
  public showSubscriptionPlans: boolean = false
  public picklPacks: any[] = []
  public selectedPackId: number = 0
  public selectedPack: any = {}
  public totalAmount: number = 0
  public planName: string = ''
  public selectedDurationType: string = ''

  getBrandSubscriptionDetails () {
    this.$store.dispatch('getBrandCurrentSubscription', this.$store.state.userData.brandId).then((response: any) => {
      this.brandSubscriptionDetails = response.response.body.data
      if (this.brandSubscriptionDetails.data_access_subscription_id === null && this.brandSubscriptionDetails.dashboard_data_access === 0) {
        this.showSubscriptionPlans = true
        this.getSubscriptionPacks()
      } else if (this.brandSubscriptionDetails.dashboard_data_access === 1) {
        this.$router.push('brand/dashboard')
      }
    }, response => {
    })
  }
  checkoutDataAccessSubscription () {
    this.totalAmount = this.selectedPack.price
    this.planName = this.selectedPack.name
    this.selectedDurationType = this.selectedPack.duration_type
    console.log(this.selectedDurationType)
  }
  SubscribePack (token: any) {
    this.$store.commit('showLoading')

    let brandId = this.$store.state.userData.brandId
    this.$http
      .post(`brands/${brandId}/subscribe-data-access-pack`, { 'token_details': token, 'pack_details': this.selectedPack })
      .then((response: any) => {
        if (response.body.status === true) {
          this.$router.push('/brand/dashboard')
          this.$store.commit('showMessage', { message: 'Transaction<br /> Successful!' })
        } else {
          this.$notify({ type: 'error', text: response.body, duration: -1 })
          this.$store.commit('hideMessage')
        }
      }, response => {
        this.$notify({ type: 'error', text: 'An error occurred while processing your transaction...', duration: -1 })
        this.$store.commit('hideMessage')
      }
      )
  }

  setPlanToSubscribe (pack: any) {
    this.selectedPackId = pack.id
    this.selectedPack = pack
  }
  getSubscriptionPacks () {
    this.$http
      .get('data_access_subscriptions')
      .then((response: any) => {
        this.picklPacks = response.body.data
        this.$store.commit('hideMessage')
      }
      )
  }

  mounted () {
    this.$store.commit('showLoading', 'Loading Brand Details....')

    this.getBrandSubscriptionDetails()
  }
}
